<template>
  <div>
    <v-row class="text-center text-md-left">
      <v-col cols="6" sm="4" md="2" v-for="(year, index) in years" :key="index" class="first_row_boxes">
        <div
          class="box_year d-flex align-center justify-center"
          :class="index === index_selected ? 'box_selected' : 'box_not_selected'"
          :id="`box${index}`"
          @click="box_click(index)"
        >
          <p class="mb-0">{{ year.value }}</p>
        </div>
      </v-col>
      <v-col cols="12" class="pt-n5 mt-n5 pt-md-n12 mt-md-n12 text-left">
        <span>
          <b>{{ year_selected }}</b> {{ text_selected }}
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "curriculum",
  data: () => ({
    year_selected: "",
    text_selected: "",
    index_selected: null,
    years: [
      {
        value: "1982",
        text: "Nacimiento.",
      },
      {
        value: "1986",
        text: "Muere Jorge Luis Borges.",
      },
      {
        value: "1987",
        text: "Descubre Star Wars.",
      },
      {
        value: "2002",
        text: "Lee El Aleph de Borges.",
      },
      {
        value: "2010",
        text: "Co-funda Bluemove.",
      },
      {
        value: "2012",
        text: "Funda TuuuLibrería.",
      },
      {
        value: "2015",
        text: "Funda Microwd. Máster de Filosofía teórica y práctica (UNED). Funda Ánima Ventures.",
      },
      {
        value: "2017",
        text: "Descubre la obra de Antonio Escohotado.",
      },
      {
        value: "2019",
        text: "EMBA + Humanidades (IE+Brown University).",
      },
      {
        value: "2021",
        text: "Muere Antonio Escohotado, su referente intelectual.",
      },
    ],
  }),
  methods: {
    ...mapMutations(["changeToSecondPage"]),
    box_click(index) {
      this.index_selected = index;
      this.year_selected = this.years[index].value;
      this.text_selected = this.years[index].text;
    },
  },
  mounted() {
    this.changeToSecondPage(true);
  },
};
</script>

<style scoped>
.first_row_boxes {
  margin-bottom: 40px !important;
}

.box_selected {
  color: #ffc72a;
}

.box_not_selected {
  color: white;
}

.box_year {
  padding: 28px;
  background-color: #000000;
  cursor: pointer;
}

@media only screen and (min-width: 960px) {
  .first_row_boxes {
    margin-bottom: 100px;
  }

  .box_year {
    padding: 40px;
  }
}
</style>
